
export const stylesVariables = {
    background: '#FEF7FA',
    secondaryText: 'white',
    primary: 'black',
    secondary: '#BCA37D',
    tertiary: '#2E2E2E',
}



